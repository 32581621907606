import { motion } from "framer-motion";

const ButtonContactWhatsapp = () => {
  return (
    <motion.a
      href="https://api.whatsapp.com/send?phone=528332032681"
      target="_blank"
      class="contactbtn overflow-hidden transition-all inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center rounded-lg text-figma-text border-figma-primary/80 border"
      title="Contactar por WhatsApp"
    >
      Hablar con un vendedor
    </motion.a>
  );
};

export default ButtonContactWhatsapp;
